import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import parse from 'html-react-parser'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { MappedImage } from '../Image'
import { textStyle } from '../../utils'
import { useSearchParams } from '../../libs/hooks'

export const ProviderCard = ({
  heading,
  subHeading,
  style,
  richtext,
  buttonText,
  buttonLink,
  brandingImage,
  htmlText,
  orgId,
  payerLink,
}) => {
  const url = useSearchParams(buttonLink, orgId, payerLink)

  return (
    <Container fluid className="outer">
      <Row className="p-3 m-auto text-center" style={{ maxWidth: '1200px' }}>
        <div className="text-center module-heading-wrapper">
          <h2
            className="module-heading my-3"
            css={textStyle(style?.headingColor)}
          >
            {heading}
          </h2>
        </div>
        <Row className="provider-card-wrapper">
          <Row className="provider-card-top">
            <Col sm={12} md={3}>
              <a href={url} className="">
                <MappedImage
                  className="m-auto my-3 text-center"
                  image={brandingImage}
                />
              </a>
            </Col>
            <Col sm={12} md={6}>
              <div className="subheading pc-subheading">
                <h3>{subHeading}</h3>
              </div>
              {richtext && richtext.raw && (
                <div className="provider-benefits text-left">
                  {documentToReactComponents(JSON.parse(richtext.raw))}
                </div>
              )}
            </Col>
            <Col sm={12} md={3}>
              <div className="btn-outer">
                <div className="btn-inner">
                  <a href={url} className="my-4 site-btn">
                    {buttonText}
                  </a>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="provider-card-bottom">
            <Col>{parse(htmlText.htmlText)}</Col>
          </Row>
        </Row>
      </Row>
    </Container>
  )
}

export const mapProviderCardProps = props => props
